class Config {
    constructor() {
        this.backendHost = "/b";
        this.currencyFormat = "0[.]00 $";
        this.grammagesFormat = "0[.]00";
        this.selectedTheme = "Bios"; // möglich ["Bios","Rewe","Sandbox"] default="Bios"
        this.selectedImpressum = "FoodGenius"; // möglich ["FoodGenius","Bios"] default="FoodGenius"
        this.imageStorageBlobURL = "https://ggbiosmedia.blob.core.windows.net/ggbiosmedia/";
        this.apiUrl = "https://functions.foodgenius.de/";
        // this.publicationApiUrl = "http://localhost:7071/marketing-dev";
        this.publicationApiUrl =
            process.env.NODE_ENV === "development" ? this.apiUrl + "marketing-dev" : this.apiUrl + "marketing";
        // this.coreApiUrl = "http://localhost:7071/core";
        this.coreApiUrl = process.env.NODE_ENV === "development" ? this.apiUrl + "core-dev" : this.apiUrl + "core";
        this.biosClientToken =
            process.env.NODE_ENV === "development" && (!window._env_ || !window._env_.REACT_APP_BIOS_CLIENT_TOKEN)
                ? "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJLZXlWYXVsdE5hbWUiOiJCSU9TIiwiUmVhbG0iOiJkZXYifQ.6QMpHGrDwMfMxWddGHtvoLUxrUGb08DJ3HsCuFjzGC0"
                : window._env_?.REACT_APP_BIOS_CLIENT_TOKEN ||
                  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJLZXlWYXVsdE5hbWUiOiJCSU9TIiwiUmVhbG0iOiJkZXYifQ.6QMpHGrDwMfMxWddGHtvoLUxrUGb08DJ3HsCuFjzGC0";
        this.metaClientId = "358160913985941";
        this.chiliEnvironment = "cp-yor-362";
        this.chiliApiUrl = `https://${this.chiliEnvironment}.chili-publish.online/grafx/api/v1/environment/${this.chiliEnvironment}`;
        this.chiliUserInterfaceId = "fee2bd43-6738-40e6-a8b1-6aad66a5867a";
    }
}

export default Config;
